/* eslint-disable import/no-unresolved */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import React from "react";
import { graphql, PageProps } from "gatsby";
import { Query } from "../../generated/graphql-types";
import { Hero, Statement } from "../../components";
// eslint-disable-next-line import/named
import { buildMenu, Layout } from "../../layouts";
import { rtfFromText } from "@bond-london/graphcms-rich-text";
import { GA_paragraph } from "../../components/GA_paragrph";
import { GA_CTA } from "../../components/GA_CTA";
import headerimage from "../../images/Xzytrr.png";
import CarbonInfograph from "../../images/Socialcorbon.png";
import socialenergytransition from "../../images/SocialEnergy.png";
import socialnetzero from "../../images/SocialDecorboninfograph.png";
import { Researchblock } from "../../components/DownloadBlock";

const Image = {
  layout: "constrained",
  backgroundColor: "#282828",
  images: {
    fallback: {
      sizes: "(min-width: 1280px) 1280px, 100vw",
      src: headerimage,
      srcSet: headerimage,
      sources: [],
      layout: "constrained",
      width: 1280,
    },
  },
  width: 1280,
  height: 720,
};

const message = {
  cleaned: {
    children: {
      text: "Partnership is not just part of our name, it is co… and how we work in global management consulting.",
      type: "paragraph",
    },
  },
};

const ResearchDocument: React.FC<
  PageProps<Pick<Query, "allGraphCmsResearchDocument" | "graphCmsSectorPage">>
> = ({
  path,
  data: {
    allGraphCmsResearchDocument: { nodes },
    graphCmsSectorPage: sectorPage,
  },
}) => {
  nodes.map((n) => console.log(n.id));
  if (!ResearchDocument) return null;
  return (
    <Layout
      pagePath={path}
      menu={buildMenu(sectorPage?.menu)}
      footerMenu={buildMenu(sectorPage?.footerMenu)}
      keywords={sectorPage?.keywords}
      title="climate-action | Partners in Performance | Global Management Consultancy"
      description={sectorPage?.description}
    >
      <Hero
        title="Our commitment to net zero"
        video=""
        image={Image}
        short={true}
        treatment={sectorPage?.heroTreatment}
        showSearch={true}
      />
      <Statement
        title="In line with our ‘no harm’ culture, we are committed to taking urgent action to protect the environment. "
        headline=""
        cleanedMessage={rtfFromText(
          "We recognise the crucial role that businesses can play in minimising the risk climate change poses to the future of the planet and as such are committed to the SBTi Net-Zero standard to set a robust emissions reduction target at the pace and scale required by climate science."
        )}
        message={message}
        id={""}
        className=""
      />
      <GA_paragraph
        id={"info2"}
        title="We have been carbon neutral since 2007"
        messagez="We primarily offset our  CO"
        messagez1="2"
        messagez2="  emissions through three initiative projects "
        className="ga-paragraph-title text-center"
        messageclass="text-center"
        // notes={[
        //   {
        //     value:
        //       "Rainforest protection in Peru, whereby 1.3m hectares land is protected and emissions are offset by >1.5 million tonnes.",
        //   },
        //   {
        //     value:
        //       " Windfarm construction and operation that provides energy to the local communities in Chitradurga, India.",
        //   },
        //   {
        //     value: "Fire management in Arnhem Land in Australia..",
        //   },
        // ]}
        CarbonInfographImage={CarbonInfograph}
      />
      <GA_paragraph
        id={"info3"}
        title="We have set an internal target to reach Net Zero value chain GHG emissions by no later than 2050 in line with the SBTi Net-Zero standard."
        messagez=""
        infographicTitle1="To help achieve this goal we have launched a ‘Decarbonising Partners in Performance’ initiative. 40 employees across six initiative teams have been tasked to implement the following globally."
        CarbonInfographImage={socialnetzero}
        className="ga-paragraph-title text-center"
        componetcolor="Grey"
      />

      <Researchblock
        quote="Our Energy Transition Practice is dedicated to helping our client partners to develop pathways to zero emissions. "
        Title="Our client work in this space"
        Link="/en/service/energy-transition#clientstories"
        CTA="Insights"
      />
      {/* <GA_imageQuote
        id={""}
        title="Our client work in this space"
        Quote="Our Energy Transition Practice is dedicated to helping our client partners to develop pathways to zero emissions. "
        position="LEFT"
        image={ourEnergy}
        className="ga-padding-50 "
      /> */}

      <GA_paragraph
        id={"info4"}
        title=""
        infographicTitle2=""
        noncategeoryContent="Our efforts over the past three years have delivered Energy Transition roadmaps for our clients to abate ~40 MtCO"
        noncategeoryContent1="2"
        noncategeoryContent2="e per annum."
        // Infographic2={[
        //   {
        //     title: "Reducing energy",
        //     value:
        //       "Improving energy productivity, capturing and reusing energy and changing consumption behaviours.",
        //   },
        //   {
        //     title: "Electrification",
        //     value:
        //       "Moving to low carbon technologies and electrification of process heat, transport and material movement.",
        //   },
        //   {
        //     title: "Moving to renewables ",
        //     value:
        //       "Increasing low emission renewable energy, shifting usage outside peaks and electrifying processes.",
        //   },
        //   {
        //     title: "Mitigation ",
        //     value:
        //       "Offsetting emissions, mitigating key risks and creating new opportunities and options.",
        //   },
        // ]}
        CarbonInfographImage={socialenergytransition}
        className="ga-paragraph-title text-center"
      />
      {/* <GA_paragraph
        id={""}
        title=""
        messagez=""
        infographicTitle2=""
        learnmore="Yes"
        className="ga-paragraph-title text-center"
      /> */}
      <GA_CTA
        id={""}
        title1="Inclusion & Diversity"
        title2="Corporate Social Responsibility"
        Link1="/en/diversity"
        Link2="/en/corporate-social-responsibility"
      />
    </Layout>
  );
};

export default ResearchDocument;

export const IndexQuery = graphql`
  {
    allGraphCmsResearchDocument {
      nodes {
        id
        documentName
        documentThumbnail {
          url
        }
        documentDescription
        documents {
          url
        }
      }
    }
    graphCmsSectorPage {
      sectorType
      pageTitle
      keywords
      description
      menu {
        ...MenuFragment
      }
      businessChallengesIntroduction
      businessChallenges {
        headline
        id
        content {
          cleaned
        }
      }
      heroImage {
        ...ImageFragment
      }
      statement {
        cleaned
      }
      heroVideo {
        ...VideoFragment
      }
      heroTreatment
      id
      keyStatistic
      leadership {
        ...ProfileFragment
      }
      leadershipTitle
      quote
      quoteFrom {
        ...ProfileFragment
      }
      infographicIntroduction {
        cleaned
      }
      infographicDetails {
        cleaned
      }
      genericInfographicText {
        cleaned
      }
      sector
      slug
      statisticDetails
      storiesTitle
      storiesImage {
        ...ImageFragment
      }
      successes {
        __typename
        ... on GraphCMS_CaseStudy {
          ...CaseStudyReferenceFragment
        }
        ... on GraphCMS_Story {
          ...StoryReferenceFragment
        }
        ... on GraphCMS_Insight {
          ...InsightReferenceFragment
        }
      }
      form {
        ...FormFragment
      }
    }
  }
`;
